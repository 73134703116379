import React, {useEffect} from 'react';
import AudioPlayer from './layout/AudioPlayer';
import Transcript from './layout/Transcript';
import Gallery from './layout/Gallery';

const BottomWidget = (props) => {

const {activePointData, widgetRef,audioPlayStatus, setAudioPlayStatus, mapTop,currentStopNumber, activeRouteName} = props;

    const checkElementContent = () => {
        const element = document.getElementById('widget');
        if (element && !element.innerHTML.trim()) {
            // If the element is empty (no text or child elements)
            element.style.display = 'none';
        } else {
            element.style.display = 'flex'; // Reset the background if not empty
        }
    };

    useEffect(() => {
        checkElementContent();
    }, [activePointData]);

    return <div id="widget" ref={widgetRef}>
            {activePointData?.audio &&
                    <AudioPlayer audioPlayStatus={audioPlayStatus} url={activePointData.audio.url}/>}
            {activePointData?.text &&
                    <Transcript mapTop={mapTop} setAudioPlayStatus={setAudioPlayStatus} transcript={activePointData.text}/>}
            {activePointData?.gallery?.length > 0 &&
                    <Gallery mapTop={mapTop} setAudioPlayStatus={setAudioPlayStatus}/>}

        {/*{(!activePointData?.audio && !activePointData?.text && !activePointData?.gallery) && currentStopNumber !== null && <div className="d-flex align-items-center justify-content-center w-100 text-white text-center p-2" style={{background:'#001233'}}>You are on the start of the tour:<br/> {activeRouteName}</div>}*/}
        </div>;
};

export default BottomWidget;