import React, {useState} from 'react';
import {Rating} from '@smastrom/react-rating';
import {useForm, Controller} from 'react-hook-form';
import '@smastrom/react-rating/style.css';
import Modal from 'react-bootstrap/Modal';
import arrow from '../../images/arrow2.svg';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import SubmitReview from './SubmitReview';
import arrowLeft from '../../images/arrowLeft.svg';

const Review = (props) => {
    const {routeId} = useParams();

    const {
        register,
        handleSubmit,
        control,
        formState: {errors},
    } = useForm({
        mode: 'onBlur',
        defaultValues: {
            rating: 0,
            message: '',
        },
    });

    const [thankYouMessage, setThankYouMessage] = useState(false);
    const [spinner, setSpinner] = useState(false);

    const submitReview = async (postId, reviewText, rating) => {
        try {
            const response = await axios.post(process.env.REACT_APP_POST_REVIEW + `/${routeId}`, {
                post_id: routeId,
                review_text: reviewText,
                rating: rating,
            });
            setThankYouMessage(true);
            localStorage.setItem('routeReview' + routeId, 'true');
            setSpinner(false)
        } catch (error) {
            console.error('Error submitting review:', error);
            setThankYouMessage(false);
        }
    };

    function onSubmit(data) {
        setSpinner(true)
        submitReview(routeId, data.message, data.rating);
        // alert(JSON.stringify(data, undefined, 2));
    }

    const {setReviewModal, reviewModal} = props;
    const hideReviewModal = () => {
        setReviewModal(false);
    };

    return (<>
                <Modal size="sm"
                       fullscreen={true}
                       id={'review-modal'}
                       scrollable={false}
                       dialogClassName={'d-flex align-items-center m-0'}
                       aria-labelledby="contained-modal-title-vcenter"
                       centered
                       backdrop={'static'}
                       className={'p-3 m-0 info-modal'}
                       show={reviewModal} onHide={hideReviewModal}
                >
                    <Modal.Body className={!thankYouMessage ? 'p-3' : 'p-0  overflow-hidden'}>

                        {!thankYouMessage ? <div className="review-form">
                            <h3 className="mt-3 mb-4" style={{fontSize: '22px'}}>Leave us some feedback!<br/>We’d love
                                to
                                hear from you.</h3>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <label htmlFor="message" className="w-100">
                                        <textarea className="w-100 p-3" placeholder="Type here…" type="textarea"
                                                  id="message" {...register('message', {required: false})}/>
                                </label>

                                <div className="my-4"><Controller
                                        control={control}
                                        name="rating"
                                        rules={{
                                            validate: (rating) => rating > 0,
                                        }}
                                        render={({field: {onChange, onBlur, value}}) => (
                                                <Rating
                                                        value={value}
                                                        isRequired
                                                        onChange={onChange}
                                                        visibleLabelId="rating_label"
                                                        onBlur={onBlur}
                                                />
                                        )}
                                />
                                    {errors.rating && <span className="d-block">Rating is required.</span>}
                                </div>
                                {!spinner ? <div className="d-flex justify-content-between"><div style={{minWidth:'45%'}}><a style={{background:'#fff',color:'#000'}}
                                        className="button w-100 d-inline-flex align-items-center justify-content-between gap-3"
                                        href="/locations/5"><img src={arrowLeft}
                                                                 alt="arrowLeft"/><span>Back to home</span></a></div><div style={{minWidth:'45%'}}>
                                    <button type="submit"
                                            className="button w-100 d-inline-flex align-items-center justify-content-between gap-3">
                                        <span>Submit</span><img style={{width: 40, height: 'auto'}}
                                                                src={arrow}
                                                                alt="arrowLeft"/></button></div>
                                </div> : <div className="spinner"></div>}
                            </form>
                        </div> : <SubmitReview/>}


                    </Modal.Body>
                </Modal>
            </>
    )
            ;
};

export default Review;