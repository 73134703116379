import React from 'react';
import hero from '../images/hero2.png';
import appview from '../images/WMT_LQ_APP_MOCKUP_ALL.png';
import line1 from '../images/line1.png';
import line2 from '../images/line2.png';
import aboutImage from '../images/aboutImage.png';


const Desktop = () => {

    return (
            <>
                <header className="">
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="logo">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                     width="348.975" height="59.478" viewBox="0 0 348.975 59.478">
                                    <defs>
                                        <clipPath id="clipPath">
                                            <rect id="Rectangle_92" data-name="Rectangle 92" width="348.975"
                                                  height="59.478"
                                                  fill="#f8f3ff"/>
                                        </clipPath>
                                    </defs>
                                    <g id="Group_150" data-name="Group 150" transform="translate(0 0)">
                                        <g id="Group_149" data-name="Group 149" transform="translate(0 0)"
                                           clipPath="url(#clipPath)">
                                            <path id="Path_120" data-name="Path 120"
                                                  d="M46.008,0a4.941,4.941,0,0,0-3.569,1.368,4.541,4.541,0,0,0-1.422,3.409,4.472,4.472,0,0,0,1.422,3.354A4.937,4.937,0,0,0,46.008,9.5,4.848,4.848,0,0,0,49.55,8.131a4.51,4.51,0,0,0,1.4-3.354,4.58,4.58,0,0,0-1.4-3.409A4.852,4.852,0,0,0,46.008,0"
                                                  transform="translate(1.755 0)" fill="#f8f3ff"/>
                                            <rect id="Rectangle_91" data-name="Rectangle 91" width="7.622"
                                                  height="27.588"
                                                  transform="translate(43.952 12.613)" fill="#f8f3ff"/>
                                            <path id="Path_121" data-name="Path 121"
                                                  d="M74.822,23.9a40.887,40.887,0,0,0-5.072-1.261q-2.04-.43-3.032-.725a4.19,4.19,0,0,1-1.477-.7,1.414,1.414,0,0,1-.483-1.153,1.836,1.836,0,0,1,.859-1.637,4.382,4.382,0,0,1,2.415-.564,10.28,10.28,0,0,1,3.381.617,8.325,8.325,0,0,1,3.061,1.852l3.864-4.884a14.115,14.115,0,0,0-4.723-2.925,15.948,15.948,0,0,0-5.744-1.047,13.4,13.4,0,0,0-5.259,1.02,8.936,8.936,0,0,0-3.866,3.032A8.187,8.187,0,0,0,57.3,20.442a6.8,6.8,0,0,0,1.583,4.75,8.8,8.8,0,0,0,3.677,2.469,37.3,37.3,0,0,0,5.26,1.315,20.323,20.323,0,0,1,2.6.644,3.629,3.629,0,0,1,1.4.778,1.5,1.5,0,0,1,.456,1.1,1.609,1.609,0,0,1-.912,1.477,5.074,5.074,0,0,1-2.468.51,13.691,13.691,0,0,1-4.939-.994,11.131,11.131,0,0,1-4.294-2.871l-4.025,5.314a16.361,16.361,0,0,0,5.717,3.783,18.316,18.316,0,0,0,6.843,1.315,14.987,14.987,0,0,0,5.958-1.127,9.46,9.46,0,0,0,4.133-3.273,8.4,8.4,0,0,0,1.5-4.939,6.452,6.452,0,0,0-1.476-4.482A8.085,8.085,0,0,0,74.822,23.9"
                                                  transform="translate(2.38 0.491)" fill="#f8f3ff"/>
                                            <path id="Path_122" data-name="Path 122"
                                                  d="M98.806,33.467a4.214,4.214,0,0,1-3.032-1.1,4.131,4.131,0,0,1-1.154-3.14V19.19h7.891V12.373H94.619V5.61H87v6.763H81.523V19.19H87V30.569a9.375,9.375,0,0,0,2.711,7.138,9.822,9.822,0,0,0,7.058,2.577,14.631,14.631,0,0,0,7.944-2.415l-1.878-5.636a7.737,7.737,0,0,1-4.026,1.235"
                                                  transform="translate(3.488 0.24)" fill="#f8f3ff"/>
                                            <path id="Path_123" data-name="Path 123"
                                                  d="M120.812,11.478a14.375,14.375,0,0,0-7.461,1.905,12.947,12.947,0,0,0-4.965,5.233,15.944,15.944,0,0,0-1.745,7.514,14.88,14.88,0,0,0,1.745,7.3,12.018,12.018,0,0,0,4.938,4.884,15.329,15.329,0,0,0,7.38,1.717,15.757,15.757,0,0,0,6.414-1.262,15.368,15.368,0,0,0,5.179-3.783l-3.865-4.67a9.086,9.086,0,0,1-7.138,3.167A6.96,6.96,0,0,1,115.9,31.47a6.755,6.755,0,0,1-1.536-3.086h18.792V25.433q0-6.763-3.274-10.359t-9.07-3.6m-6.5,11.432a7.274,7.274,0,0,1,1.35-3.14,5.689,5.689,0,0,1,4.724-2.013,5.138,5.138,0,0,1,3.918,1.368,5.492,5.492,0,0,1,1.342,3.784Z"
                                                  transform="translate(4.562 0.491)" fill="#f8f3ff"/>
                                            <path id="Path_124" data-name="Path 124"
                                                  d="M153.919,11.478a9.669,9.669,0,0,0-4.965,1.153,10.545,10.545,0,0,0-3.355,3.194v-3.7h-7.407V39.71h7.622V24.682a7.862,7.862,0,0,1,.724-3.542,4.961,4.961,0,0,1,2.014-2.174,5.7,5.7,0,0,1,2.845-.725,4.683,4.683,0,0,1,3.462,1.262,5.06,5.06,0,0,1,1.261,3.73V39.71h7.622V21.622q0-4.938-2.71-7.541a9.842,9.842,0,0,0-7.112-2.6"
                                                  transform="translate(5.912 0.491)" fill="#f8f3ff"/>
                                            <path id="Path_125" data-name="Path 125"
                                                  d="M235.051,40.006a9.05,9.05,0,0,0,4.724-1.127,10.931,10.931,0,0,0,3.328-3.22v4.025h7.353V12.1h-7.567v14.76a7.711,7.711,0,0,1-.7,3.382,5.188,5.188,0,0,1-1.959,2.227,5.455,5.455,0,0,1-2.925.778,4.18,4.18,0,0,1-3.247-1.261A5.438,5.438,0,0,1,232.9,28.2V12.1h-7.622V29.754q0,5.152,2.737,7.7a9.9,9.9,0,0,0,7.031,2.55"
                                                  transform="translate(9.638 0.518)" fill="#f8f3ff"/>
                                            <path id="Path_126" data-name="Path 126"
                                                  d="M269.716,11.478a14.374,14.374,0,0,0-7.46,1.905,12.934,12.934,0,0,0-4.965,5.233,15.933,15.933,0,0,0-1.746,7.514,14.869,14.869,0,0,0,1.746,7.3,12.012,12.012,0,0,0,4.938,4.884,15.329,15.329,0,0,0,7.38,1.717,15.757,15.757,0,0,0,6.414-1.262,15.367,15.367,0,0,0,5.18-3.783l-3.865-4.67a9.086,9.086,0,0,1-7.138,3.167,6.96,6.96,0,0,1-5.394-2.014,6.745,6.745,0,0,1-1.536-3.086h18.792V25.433q0-6.763-3.274-10.359t-9.071-3.6m-6.5,11.432a7.274,7.274,0,0,1,1.35-3.14,5.688,5.688,0,0,1,4.723-2.013,5.141,5.141,0,0,1,3.919,1.368,5.492,5.492,0,0,1,1.342,3.784Z"
                                                  transform="translate(10.933 0.491)" fill="#f8f3ff"/>
                                            <path id="Path_127" data-name="Path 127"
                                                  d="M299.724,11.478a14.373,14.373,0,0,0-7.46,1.905,12.941,12.941,0,0,0-4.965,5.233,15.933,15.933,0,0,0-1.746,7.514,14.869,14.869,0,0,0,1.746,7.3,12.012,12.012,0,0,0,4.938,4.884,15.329,15.329,0,0,0,7.38,1.717,15.757,15.757,0,0,0,6.414-1.262,15.367,15.367,0,0,0,5.179-3.783l-3.865-4.67a9.086,9.086,0,0,1-7.138,3.167,6.96,6.96,0,0,1-5.394-2.014,6.745,6.745,0,0,1-1.536-3.086H312.07V25.433q0-6.763-3.274-10.359t-9.071-3.6m-6.5,11.432a7.273,7.273,0,0,1,1.35-3.14,5.688,5.688,0,0,1,4.723-2.013,5.139,5.139,0,0,1,3.919,1.368,5.492,5.492,0,0,1,1.342,3.784Z"
                                                  transform="translate(12.216 0.491)" fill="#f8f3ff"/>
                                            <path id="Path_128" data-name="Path 128"
                                                  d="M331.813,11.787a8.823,8.823,0,0,0-4.483,1.1,6.554,6.554,0,0,0-2.817,3.409V12.109h-7.408V39.7h7.622V25.688a6.985,6.985,0,0,1,1.744-5.072,6.177,6.177,0,0,1,4.643-1.8,10.478,10.478,0,0,1,3.22.537l1.074-7.086a14.884,14.884,0,0,0-3.6-.483"
                                                  transform="translate(13.566 0.505)" fill="#f8f3ff"/>
                                            <path id="Path_129" data-name="Path 129"
                                                  d="M22.646,2.588H14.892V4.275a14.732,14.732,0,0,1-4.015,9.415A15.79,15.79,0,0,1,0,18.274l.092,7.286a23.152,23.152,0,0,0,14.8-5.688V40.022H37.674v-7.38H22.646Z"
                                                  transform="translate(0 0.111)" fill="#f8f3ff"/>
                                            <path id="Path_130" data-name="Path 130"
                                                  d="M233.7,51.241c-6.543-2.184-10.994-8.213-15.3-14.045-.788-1.068-1.582-2.138-2.391-3.188a18.926,18.926,0,0,0,1.827-2.7,20.184,20.184,0,0,0,2.492-10,20.187,20.187,0,0,0-2.492-10,18.32,18.32,0,0,0-6.915-7.048,20.029,20.029,0,0,0-19.644,0,18.567,18.567,0,0,0-6.914,7.048,20.018,20.018,0,0,0-2.519,10,20.016,20.016,0,0,0,2.519,10,18.56,18.56,0,0,0,6.914,7.048,19.054,19.054,0,0,0,9.809,2.573,19.254,19.254,0,0,0,9.28-2.274c.688.894,1.4,1.848,2.159,2.874,4.748,6.432,10.128,13.721,18.863,16.634a24.6,24.6,0,0,0,7.834,1.239c7.851,0,16.788-3.369,26.675-10.071l-4.1-6.044c-11.367,7.7-20.823,10.379-28.1,7.95m-42.5-36.2a11.112,11.112,0,0,1,4.047-4.368A10.929,10.929,0,0,1,201.083,9.1a10.954,10.954,0,0,1,9.942,5.949,13.2,13.2,0,0,1,1.475,6.271,13.2,13.2,0,0,1-1.475,6.271c-.1.184-.215.346-.319.521-2.976-2.643-6.371-4.41-10.59-4.3A16.115,16.115,0,0,0,191,27.155a13.309,13.309,0,0,1-1.279-5.84,13.2,13.2,0,0,1,1.473-6.271M196.2,32.454a8.389,8.389,0,0,1,4.113-1.346,7.292,7.292,0,0,1,4.7,1.747,11.5,11.5,0,0,1-3.928.682,11.115,11.115,0,0,1-4.884-1.082"
                                                  transform="translate(7.779 0.073)" fill="#f8f3ff"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div className="menu d-flex align-items-center gap-5">
                                <a className="text-white text-decoration-none" href="#app">The app</a>
                                <a className="text-white text-decoration-none" href="#about">About us</a>
                            </div>
                        </div>
                    </div>
                </header>
                <section className="hero">
                    <div className="d-flex align-items-center h-100 overflow-hidden">
                        <div className="container h-100">
                            <div className="row h-100">
                                <div className="col-lg-6 d-flex align-items-center">
                                    <div>
                                        <h1 className="fs-90">Get ready to discover untold LGBTQIA+ stories</h1>
                                        <div className="pe-5 mt-xl-5">
                                            <p className="fs-40 pe-5">Please switch to your mobile to meander through
                                                our queer history.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 h-100 text-end">
                                    <img className="img-fluid" style={{maxWidth: '50vw', maxHeight: '100%'}} src={hero}
                                         alt="app"/>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <section id="app" className="py-5 position-relative overflow-hidden" style={{background: '#F8F3FF'}}>
                    <div className="container py-xl-5">
                        <div className="row align-items-center pb-5">
                            <div className="col-lg-6">
                                <img className="img-fluid" src={appview} alt="appview"/>
                            </div>
                            <div className="col-lg-6">
                                <div className="px-xl-4">
                                <p className="fs-32 pt-4">ListenQueer is a personal tour guide through the rich LGBTQIA+ history of several cities, bringing to life forgotten people. Designed to be accessible, the app can be used on site, as a walking tour guide, or to explore queer history from anywhere in the world.
                                </p>
                                <p className="fs-32 mb-5">ListenQueer takes users in the footsteps of our queer, trans and nonbinary forebears, mapping their paths onto present-day spaces. Users can follow a guided walk listening to the audio descriptions and following directions provided in the app, but the recordings work in any sequence and from any location. ListenQueer provides an immersive experience to listeners at home or live on the street.
                                </p>
                                <a href="#" className="d-inline-block h-button text-decoration-none">Switch to mobile to
                                    start exploring</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img style={{right: '-123px', bottom: '-29px', width: '25vw'}} className="position-absolute"
                         src={line1} alt="line1"/>
                </section>
                <section id="about" className="py-5 position-relative overflow-hidden" style={{background: '#BC88FC'}}>
                    <div className="container py-5">
                        <div className="row align-items-end py-xl-5">
                            <div className="col-lg-4">
                                <h3 className="fs-60 mb-5">A little about us</h3>
                                <p className="fs-32 pe-3">Dr Lena Mattheis and Prof Chris Mounsey are the team behind
                                    ListenQueer. We are both researchers specialising in queer and trans literature and
                                    history and love sharing that research with others. </p>
                            </div>
                            <div className="col-lg-4">
                                <p className="fs-32 pe-3"> Lena (they/she) hosts the Queer Lit podcast and has a soft
                                    spot
                                    for queer Christmas novels.
                                    <br/><br/>
                                    Chris (he/him) works in disability studies and wears the best dungarees in town.</p>
                            </div>
                            <div className="col-lg-4 pt-5 pt-xl-0">
                                <img className="img-fluid" src={aboutImage} alt="aboutImage"/>
                            </div>
                        </div>
                    </div>
                    <img style={{left: '-6vw', top: '-50px', width: '17vw'}} className="position-absolute"
                         src={line2} alt="line1"/>
                </section>
                <footer className="py-5">
                    <div className="container py-3">
                        <div className="row">
                            <div className="col-lg-2 mb-4 mb-lg-0">
                                <svg id="Group_175" data-name="Group 175" xmlns="http://www.w3.org/2000/svg"
                                     xmlnsXlink="http://www.w3.org/1999/xlink" width="92.763" height="92.869"
                                     viewBox="0 0 92.763 92.869">
                                    <defs>
                                        <clipPath id="clip-path">
                                            <rect id="Rectangle_112" data-name="Rectangle 112" width="92.763"
                                                  height="92.869" fill="#bc88fc"/>
                                        </clipPath>
                                    </defs>
                                    <g id="Group_174" data-name="Group 174" clipPath="url(#clip-path)">
                                        <path id="Path_284" data-name="Path 284"
                                              d="M111.886,0l-1.225,9.007A37.669,37.669,0,0,1,143.1,46.222,34.609,34.609,0,0,1,139.42,59.68c-1.817,3.056-5.668,5.8-8.576,6.106a9.61,9.61,0,0,1-.983.047,6.942,6.942,0,0,1-3.79-1,11.413,11.413,0,0,1-2.654-2.718,23.605,23.605,0,0,0,2.332-3.429,25.172,25.172,0,0,0,3.107-12.461,24.93,24.93,0,0,0-4.09-14.077A22.753,22.753,0,0,0,118.746,26a24.881,24.881,0,0,0-26.107-1.029,23.159,23.159,0,0,0-8.619,8.787,24.956,24.956,0,0,0-3.141,12.462,26.915,26.915,0,0,0,.663,6.024L90.9,48.881a17.95,17.95,0,0,1-.2-2.66A16.457,16.457,0,0,1,92.539,38.4a13.853,13.853,0,0,1,5.045-5.446,13.632,13.632,0,0,1,7.283-1.971,13.659,13.659,0,0,1,12.4,7.417,16.469,16.469,0,0,1,1.838,7.818,16.368,16.368,0,0,1-2.02,8.152l-.009,0c-3.917-3.737-8.4-6.311-14.076-6.163a19.952,19.952,0,0,0-11.283,3.955c-.007-.019,0-.01,0,0-3.11,1.959-4.354,5.425-4.077,9.035A6.407,6.407,0,0,0,90.1,65.749a23.451,23.451,0,0,0,2.542,1.721,23.75,23.75,0,0,0,12.228,3.207,24.054,24.054,0,0,0,11.322-2.7,19.413,19.413,0,0,0,4.826,4.671,16.146,16.146,0,0,0,8.482,2.491c.121.007.249.007.37.007a17.466,17.466,0,0,0,1.959-.108c6.55-.694,12.824-5.89,15.632-10.664.054-.089.1-.177.148-.262a45.892,45.892,0,0,0,4.207-11.983v-.013c.1-.774.182-1.555.242-2.336.088-1.178.135-2.363.135-3.555A46.8,46.8,0,0,0,111.886,0m-7.019,61.455A13.592,13.592,0,0,1,97.454,59.4V59.4a10.878,10.878,0,0,1,5.785-2.087c2.485-.066,4.7,1.056,7.01,3.137a14.244,14.244,0,0,1-5.382,1.007"
                                              transform="translate(-59.427 -0.001)" fill="#bc88fc"/>
                                        <path id="Path_285" data-name="Path 285"
                                              d="M46.113,236.746A46.659,46.659,0,0,1,0,197.185l8.984-1.371A37.589,37.589,0,0,0,61.8,224.235l3.8,8.255a46.272,46.272,0,0,1-19.491,4.256"
                                              transform="translate(0 -143.877)" fill="#bc88fc"/>
                                        <path id="Path_286" data-name="Path 286"
                                              d="M9.09,40.862.111,39.454A46.5,46.5,0,0,1,38.609.7l1.467,8.969A37.423,37.423,0,0,0,9.09,40.862"
                                              transform="translate(-0.082 -0.514)" fill="#bc88fc"/>
                                    </g>
                                </svg>

                            </div>
                            <div className="col-lg-4">
                                <p className="fs-24 text-white">
                                    ListenQueer is funded by the UKRI Impact Acceleration Account, the University of Surrey, and the University of Winchester.

                                </p>
                            </div>
                            <div className="col-lg-4 offset-lg-1">
                                <p className="fs-24 text-white">
                                    To find out more about the project, give feedback or just get in touch, please
                                    email:<br/>
                                    <a className="text-decoration-none text-white"
                                       href="mailto:l.mattheis@surrey.ac.uk">l.mattheis@surrey.ac.uk</a><br/>
                                    <a className="text-decoration-none text-white"
                                       href="mailto:Chris.Mounsey@winchester.ac.uk">Chris.Mounsey@winchester.ac.uk</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
    );
};
export default Desktop;