import logo from '../images/lq-logo.svg';
import React from 'react';

const Loader = () => {
    return (<div className="map-loader-holder position-absolute start-0 end-0 top-0 bottom-0 pt-5">
        <img style={{maxWidth: 200}} className="pt-5 mt-5" src={logo} alt="logo"/>
        <div id="map-loader">
            <div className="spinner"></div>
            <p>Loading map route...</p>
        </div>
    </div>);
};

export default Loader;