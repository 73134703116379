import React, {useContext, useEffect, useState} from 'react';
import arrow from '../images/arrow2.svg';
import Modal from 'react-bootstrap/Modal';
import {Settings} from '../store';
import Review from './layout/Review';
import {useParams} from 'react-router-dom';


const FinalScreen = (props) => {
    const {routeId} = useParams();
    const {setFinalModal, finalModal, startTourAgain} = props;
    const [reviewModal, setReviewModal] = useState(false);
    const [reviewAllow, setReviewAllow] = useState(true);
    const dispatch = useContext(Settings.Dispatch);

    const openReview = () => {
        setReviewModal(true);
    };


    useEffect(() => {
        const routeReview = localStorage.getItem('routeReview' + routeId);
        if (routeReview) {
            setReviewAllow(false);
        }

    }, []);


    useEffect(() => {
        if(finalModal) {
        dispatch({
            type: 'setStates',
            payload: {
                audioModal: false,
                transcriptModal: false,
                galleryModal: false,
            },
        });
        }
    }, [finalModal]);


    return <>
        <Modal size="sm"
               fullscreen={false}
               id={'final-modal'}
               scrollable={false}
               dialogClassName={'d-flex align-items-center m-0'}
               aria-labelledby="contained-modal-title-vcenter"
               centered
               backdrop="static"
               className={'p-3 m-0 final-modal'}
               show={finalModal} onHide={() => setFinalModal(false)}
        >
            <Modal.Header className="p-3">
            </Modal.Header>
            <Modal.Body className={'p-0'}>
                <div className="p-4 pt-3">
                    <h3 style={{fontSize: '22px'}} className="mb-4">You have now reached the end of the tour. Thank you
                        for joining us!
                    </h3>
                    <h3 style={{fontSize: '22px'}} className="mb-4">Where to next? </h3>
                    <a className="button d-inline-flex align-items-center justify-content-center gap-3"
                       onClick={(e) => {
                           e.preventDefault();
                           startTourAgain();
                       }}><span>Start the tour again!</span><img style={{minWidth: 26, maxWidth: 26, height: 'auto'}}
                                                                 src={arrow}
                                                                 alt="arrowLeft"/></a><br/><br/>
                    <a href={'/locations/5/'}
                       className="button d-inline-flex align-items-center justify-content-center gap-3"><span>Explore other tours</span><img
                            style={{width: 40, height: 'auto'}} src={arrow}
                            alt="arrowLeft"/></a>
                    <br/><br/>
                    {reviewAllow && <a onClick={openReview} href={'#'}
                                       className="button d-inline-flex align-items-center justify-content-center gap-3"><span>Write a review</span><img
                            style={{width: 40, height: 'auto'}} src={arrow}
                            alt="arrowLeft"/></a>}

                </div>
                <Review setReviewModal={setReviewModal} reviewModal={reviewModal}/>
            </Modal.Body>
        </Modal>

    </>;
};


export default FinalScreen;