import React, {useContext, useEffect, useRef, useState} from 'react';
import ReactPlayer from 'react-player';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import arrowDark from '../../images/arrow-dark.svg';
import playButton from '../../images/playButton.svg';
import rewind from '../../images/rewind.svg';
import forward from '../../images/forward.svg';
import pauseButton from '../../images/pause.svg';

import {Settings} from '../../store';
import transcript from './Transcript';

const AudioPlayer = (props) => {
    const {url, audioPlayStatus} = props;
    const dispatch = useContext(Settings.Dispatch);
    const settingsState = useContext(Settings.State);
    const {audioModal, transcriptModal, galleryModal} = settingsState;
    const playerRef = useRef(null);
    const [playing, setPlaying] = useState(audioPlayStatus);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isDragging, setIsDragging] = useState(false);


    const hideAudioModal = () => {
        dispatch({
            type: 'setStates',
            payload: {
                audioModal: false,
            },
        });
    };
    const showAudioModal = () => {
        dispatch({
            type: 'setStates',
            payload: {
                audioModal: true,
                transcriptModal: false,
                galleryModal: false,
            },
        });
    };

    // Format time to mm:ss
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };
    // Play/Pause toggle
    const handlePlayPause = () => {
        setPlaying(!playing);
    };

    // Stop the audio
    const handleStop = () => {
        setPlaying(false);
        playerRef.current.seekTo(0);
    };

    const handleRewind = () => {
        const currentTime = playerRef.current.getCurrentTime();
        playerRef.current.seekTo(currentTime - 10 > 0 ? currentTime - 10 : 0);
    };

    useEffect(() => {
        setPlaying(audioPlayStatus);
    }, [audioPlayStatus]);

    const handleForward = () => {
        const currentTime = playerRef.current.getCurrentTime();
        const duration = playerRef.current.getDuration();
        playerRef.current.seekTo(currentTime + 10 < duration ? currentTime + 10 : duration);
    };

    const handleProgress = (state) => {
        if (!isDragging) {
            setCurrentTime(state.playedSeconds);
        }
    };

    const handleDuration = (duration) => setDuration(duration);

    const handleDotDragStart = () => setIsDragging(true);

    const handleDotDragEnd = () => setIsDragging(false);

    const handleDotDrag = (e) => {
        if (isDragging) {
            const rect = e.currentTarget.getBoundingClientRect();
            const clickX = e.type === "touchmove" ? e.touches[0].clientX - rect.left : e.clientX - rect.left;
            const newTime = Math.max(0, Math.min((clickX / rect.width) * duration, duration));
            setCurrentTime(newTime);
            playerRef.current.seekTo(newTime / duration);
        }
    };

    const handleSeekChange = (e) => {
        setCurrentTime(parseFloat(e.target.value));
        playerRef.current.seekTo(parseFloat(e.target.value) / duration);
    };

    const handleProgressClick = (e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const clickX = e.clientX - rect.left;
        const newTime = (clickX / rect.width) * duration;
        playerRef.current.seekTo(newTime);
        setCurrentTime(newTime);
    };

    const progressPercentage = (currentTime / duration) * 100 || 0;

    const handleEnded = () => {
        playerRef.current.seekTo(0);
        setPlaying(false);
    };

    return (<>
                {!audioModal || (transcriptModal || galleryModal) ?
                        <Button className="audio-button" onClick={showAudioModal}><span>Audio</span><img src={arrowDark}
                                                                                                         alt=""/></Button> : ''}
                <Modal size="sm"
                       fullscreen={false}
                       id={'audio-modal'}
                       scrollable={false}
                       dialogClassName={'d-flex align-items-start m-0'}
                       aria-labelledby="contained-modal-title-vcenter"
                       backdrop={false}
                       className={'p-0 m-0 audio-modal'}
                       show={audioModal} onHide={hideAudioModal}
                >
                    <Modal.Header className="p-0" closeButton>
                    </Modal.Header>
                    <Modal.Body className={'p-0'}>
                        <div className="mt-3">
                            <ReactPlayer
                                    bottom={'sd'}
                                    ref={playerRef}
                                    url={url}
                                    playing={playing}
                                    controls={false}
                                    width="0px"
                                    height="0px"
                                    onProgress={handleProgress}
                                    onDuration={handleDuration}
                                    onEnded={handleEnded}

                            />
                            <div
                                    className="progress-bar"
                                    onClick={handleProgressClick}
                                    onMouseDown={handleDotDragStart}
                                    onTouchStart={handleDotDragStart}
                                    onMouseMove={handleDotDrag}
                                    onTouchMove={handleDotDrag}
                                    onMouseUp={handleDotDragEnd}
                                    onTouchEnd={handleDotDragEnd}
                            >
                                <div
                                        className="progress-filled"
                                        style={{width: `${progressPercentage}%`}}
                                ><span className="dot"></span></div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1"
                                 style={{fontSize: 12}}>
                                <span>{formatTime(currentTime)}</span>
                                <span>{formatTime(duration)}</span>
                            </div>
                            <div className="controls text-center align-items-center d-flex justify-content-center">
                                <span className="d-block"  role="button" onClick={handleRewind}><img src={rewind} alt="rewind"/></span>
                                <span className="d-block" role="button" style={{margin: '0 16px'}}
                                      onClick={handlePlayPause}>{playing ?
                                        <img src={pauseButton}/> :
                                        <img src={playButton}/>}
                    </span>
                                <span className="d-block"  role="button" onClick={handleForward}><img src={forward} alt="forward"/></span>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
    )
            ;
};

export default AudioPlayer;
