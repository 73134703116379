import React, {useRef, useEffect, useState, useContext} from 'react';
import mapboxgl from 'mapbox-gl';
import {calculateMidpoint, detectStopNumber, mapFlyTo, setActivePin} from '../hooks/helpers';
import {v4 as uuidv4} from 'uuid';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import 'mapbox-gl/dist/mapbox-gl.css';
import {Settings} from '../store';

import InfoModal from './InfoModal';
import Loader from './Loader';
import Stops from './Stops';
import FinalScreen from './FinalScreen';
import BottomWidget from './BottomWidget';

import arrowDark from '../images/arrow-dark.svg';
import zoomIn from '../images/zoom-in-map.svg';
import zoomOut from '../images/zoom-out-map.svg';
import arrowLeft from '../images/arrowLeft.svg';
import homeIcon from '../images/homeIcon.svg';
import infoIcon from '../images/infoIcon.svg';
import marker from '../images/marker.svg';
import markerActive from '../images/markerActive.svg';
import markerSmallDark from '../images/marker-small-dark.svg';


const Mapbox = () => {
    const [mapLoaded, setMapLoaded] = useState(false);
    const {routeId} = useParams();
    const mapContainer = useRef(null);
    const markerRef = useRef(null);
    const mapRef = useRef(null);
    const [mapData, setMapData] = useState([]);
    const [coords, setCoords] = useState([]);
    const titleRef = useRef(null);
    const buttonRef = useRef(null);
    const [mapTop, setMapTop] = useState('139px');
    const [audioPlayStatus, setAudioPlayStatus] = useState(false);
    const [mapObject, setMapObject] = useState(null);
    const widgetRef = useRef(null);
    const [openStops, setOpenStops] = useState(false);
    const [infoModal, setInfoModal] = useState(false);
    const dispatch = useContext(Settings.Dispatch);
    const settingsState = useContext(Settings.State);
    const {activePointData, activeRouteName, currentStopNumber, totalStops, hideTopWidget} = settingsState;
    const [finalModal, setFinalModal] = useState(false);
    const geolocateControlRef = useRef(null);


    let markers = [];
    let lines = [];


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_ROUTE_API}/?route_id=${routeId}`).then(response => {
            const ajaxData = response.data.data;
            const [initialLng, initialLat] = ajaxData[0].geometry.coordinates[0];

            setMapData(ajaxData);
            setCoords([initialLng, initialLat]);

            let allStops = [];
            let i = 1;

            ajaxData.forEach((item) => {
                const {properties} = item;
                if (properties && properties.props) {
                    const {props} = properties;
                    const addStop = (stopTitle, stopGallery, stopText, stopAudio, middlePoint = false) => {
                        allStops.push({
                            id: i++,
                            lng: properties.lng,
                            lat: properties.lat,
                            gallery: stopGallery,
                            text: stopText,
                            title: stopTitle,
                            audio: stopAudio,
                            markerId: properties.id,
                            middlePoint,
                        });
                    };

                    // Add current stop if title exists
                    if (props.title) {
                        addStop(props.title, props.gallery, props.text, props.audio);
                    }

                    // Add next stop if title_next exists
                    if (props.title_next) {
                        addStop(props.title_next, props.gallery_next, props.text_next, props.audio_next, true);
                    }
                }
            });

            dispatch({type: 'setActiveRouteName', payload: response.data.route_title});
            dispatch({type: 'setTotalStops', payload: allStops});
        }).catch(error => console.error('Error:', error));
    }, []);


    useEffect(() => {

        if (finalModal) {
            dispatch({
                type: 'setHideTopWidget', payload: true,
            });
        } else {
            dispatch({
                type: 'setHideTopWidget', payload: false,
            });
        }

    }, [finalModal]);

    if (mapData.length) {
        markers = mapData.filter((feature) => feature.geometry && feature.geometry.type === 'Point');
        lines = mapData.filter((x) => x.geometry.type === 'LineString');
    }
    const updateMapTop = () => {
        const titleHeight = titleRef.current ? titleRef.current.offsetHeight : 0;
        const buttonHeight = buttonRef.current ? buttonRef.current.offsetHeight : 0;
        setMapTop(`${titleHeight + buttonHeight}px`);
    };

    useEffect(() => {
        updateMapTop();
        window.addEventListener('resize', updateMapTop);
        return () => window.removeEventListener('resize', updateMapTop);
    }, [activeRouteName, activePointData]);

    useEffect(() => {
        if (!mapData.length || !coords.length) return;

        mapboxgl.accessToken = process.env.REACT_APP_MAPAPI;
        const map = (mapRef.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: coords,
            zoom: 14,
        }));

        geolocateControlRef.current = new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true,
            },
            trackUserLocation: true,
            showUserLocation: true,
            showUserHeading: true,
        });

        mapRef.current.addControl(geolocateControlRef.current);

        setMapObject(map);

        map.on('load', async () => {
            const bounds = markers.reduce(
                    (bounds, marker) => bounds.extend(marker.geometry.coordinates),
                    new mapboxgl.LngLatBounds(markers[0].geometry.coordinates, markers[0].geometry.coordinates),
            );


            map.fitBounds(bounds, {padding: 50});


            // Initialize and trigger the geolocate control on map load
            geolocateControlRef.current = new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true,
                },
                trackUserLocation: true,
                showUserLocation: true,
            });
            geolocateControlRef.current._updateCamera = () => {
            };
            mapRef.current.addControl(geolocateControlRef.current);

            // geolocateControlRef.current.on('geolocate', (e) => {
            //     const { coords } = e;
            //     const { latitude, longitude } = coords;
            //     console.log(mapRef.current)
            //     if (!lockBounds) {
            //         map.fitBounds(bounds, {padding: 50});
            //         setLockBounds(true)
            //     }
            //
            // });

            // Trigger the geolocation without moving the map center
            setTimeout(() => {
                geolocateControlRef.current.trigger();
            }, 5000);


            const routeId = 'route_' + uuidv4();
            map.addSource(routeId, {
                type: 'geojson', data: {
                    type: 'FeatureCollection', features: lines,
                },
            });

            map.addLayer({
                id: 'route', type: 'line', source: routeId, layout: {
                    'line-join': 'round', 'line-cap': 'round',
                }, paint: {
                    'line-color': 'rgba(188,136,252,0.7)', 'line-width': 7,
                },
            });

            const normalPointImg = new Image(57, 86);
            normalPointImg.src = marker; // Replace with the path to your normal marker image
            normalPointImg.onload = () => {
                map.addImage('normal-marker', normalPointImg);
            };

            const activePointImg = new Image(74, 112);
            activePointImg.src = markerActive; // Replace with the path to your active marker image
            activePointImg.onload = () => {
                map.addImage('active-marker', activePointImg);
            };

            const pointImg = new Image(33, 50);
            pointImg.src = markerSmallDark;
            pointImg.number += 1;
            pointImg.onload = () => {
                map.addImage('point-marker', pointImg);

                // Points
                const pointsId = 'points_' + uuidv4();
                map.addSource(pointsId, {
                    type: 'geojson', data: {
                        type: 'FeatureCollection', features: markers,
                    },
                });

                map.addLayer({
                    id: 'pointMarker',
                    type: 'symbol',
                    source: pointsId,
                    layout: {
                        'icon-image': 'point-marker',
                        'text-field': ['get', 'number'],
                        'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
                        'text-offset': [0, -1.6],
                        'text-size': 18,
                        'text-anchor': 'center',
                        'icon-offset': [0, -25],
                        'icon-allow-overlap': true,
                    }, paint: {
                        'text-color': '#fff',
                    },
                });

                setTimeout(() => {
                    setMapLoaded(true);
                }, 500);

                let activeMarkerId = null;
                // Add click event for the markers.
                map.on('click', 'pointMarker', (e) => {
                    const feature = e.features[0];
                    const clickedlng = feature.properties.lng;
                    const clickedlat = feature.properties.lat;

                    if (feature.properties.number ===1) {
                        dispatch({type: 'setAudioModal', payload: true});
                    }

                    // setAudioPlayStatus(true);
                    setActivePin(map, activeMarkerId, feature.properties.id);

                    const pointer = mapData.find((data) =>
                            data.geometry.type === 'Point' &&
                            data.geometry.coordinates[1] === clickedlat &&
                            data.geometry.coordinates[0] === clickedlng,
                    );

                    const pointerProps = pointer?.properties.props;
                    dispatch({type: 'setActivePointData', payload: pointerProps || null});


                    let stopNumber = detectStopNumber(totalStops, JSON.parse(feature.properties.props).title, pointer.properties.lng, pointer.properties.lat);
                    dispatch({type: 'setCurrentStopNumber', payload: stopNumber.id});

                    if (pointer?.properties.lat && pointer?.properties.lng) {
                        mapFlyTo(map, pointer.properties.lng, pointer.properties.lat);
                    }
                });


            };


            return () => {
                if (markerRef.current) {
                    markerRef.current.remove();
                }
                map.remove();
            };
        });

    }, [mapData, coords, totalStops]);


    // Handle zoom controls
    useEffect(() => {
        if (!mapRef.current) return;
        const map = mapRef.current;
        document.getElementById('zoom-in').addEventListener('click', () => map.zoomIn());
        document.getElementById('zoom-out').addEventListener('click', () => map.zoomOut());

    }, [mapLoaded]);

    // useEffect(() => {
    //     const parentDiv = widgetRef.current;
    //     const childDivs = parentDiv?.children;
    //     const childCount = childDivs.length;
    //
    //     if (childCount === 2) {
    //         Array.from(childDivs).forEach(div => {
    //             div.style.width = '50%';
    //         });
    //     } else if (childCount === 3) {
    //         childDivs[0].style.width = '100%';
    //         childDivs[1].style.width = '50%';
    //         childDivs[2].style.width = '50%';
    //     } else if (childCount === 1) {
    //         childDivs[0].style.width = '100%';
    //     }
    // }, []);

    const handleOpenStops = () => setOpenStops(!openStops);

    const handleStopClick = (direction) => {
        handleNextPrevStop(direction, mapObject);
    };
    const handleNextPrevStop = (type) => {
        if (currentStopNumber <= 1 && type === 'prev') return;
        let newStopNumber = type === 'prev' ? currentStopNumber - 2 : currentStopNumber + 1;

        if (newStopNumber === 0) {
            newStopNumber = 1;
        }

        if (newStopNumber > totalStops.length) {
            setFinalModal(true);
            return;
        }

        dispatch({type: 'setCurrentStopNumber', payload: newStopNumber});
        dispatch({type: 'setActivePointData', payload: totalStops[newStopNumber - 1]});

        let {lng, lat, markerId, middlePoint} = totalStops[newStopNumber - 1];

        if (middlePoint && type === 'next') {
            if (totalStops[newStopNumber + 1] != undefined) {
                lng = totalStops[newStopNumber + 1].lng;
                lat = totalStops[newStopNumber + 1].lat;
            } else {
                lng = totalStops[newStopNumber].lng;
                lat = totalStops[newStopNumber].lat;
            }

        }

        setActivePin(mapObject, null, markerId);
        mapFlyTo(mapObject, lng, lat, middlePoint && type === 'next');
        setOpenStops(false);

    };


    const startTourAgain = () => {
        dispatch({type: 'setCurrentStopNumber', payload: 1});
        dispatch({type: 'setActivePointData', payload: totalStops[0]});

        const {lng, lat, markerId} = totalStops[0];
        setActivePin(mapObject, null, markerId);
        mapFlyTo(mapObject, lng, lat);
        setFinalModal(false);
    };

    const renderNavIcon = (direction) => (
            <span className="p-2" onClick={() => handleStopClick(direction)}>
            <svg
                    style={{
                        opacity: direction === 'prev' && currentStopNumber === 1 && '0.5',
                    }}
                    id={direction === 'prev' ? 'Left' : 'Right'}
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.296"
                    height="24.582"
                    viewBox="0 0 14.296 24.582"
            >
                <defs>
                    <clipPath id="clip-path">
                        <rect id={`Rectangle_${direction === 'prev' ? 65 : 66}`} width="14.296" height="24.582"
                              fill="none"/>
                    </clipPath>
                </defs>
                <g id={`Group_${direction === 'prev' ? 82 : 84}`} clipPath="url(#clip-path)">
                    <path
                            d={direction === 'prev' ? 'M12.8,0C12.8,7.411,4.212,12.291,0,12.291' : 'M1.5,0c0,7.411,8.587,12.291,12.8,12.291'}
                            fill="none"
                            stroke="#fdfde9"
                            strokeLinejoin="bevel"
                            strokeWidth="2.995"
                    />
                    <path
                            d={direction === 'prev' ? 'M12.8,24.582c0-7.411-8.587-12.291-12.8-12.291' : 'M1.5,24.582c0-7.411,8.587-12.291,12.8-12.291'}
                            fill="none"
                            stroke="#fdfde9"
                            strokeLinejoin="bevel"
                            strokeWidth="2.995"
                    />
                </g>
            </svg>
        </span>
    );

    return (<>
        {!mapLoaded && <Loader/>}
        <div ref={titleRef} className={'map-title d-flex align-items-center justify-content-between'}>
            {currentStopNumber && renderNavIcon('prev')}
            <span className="w-100 d-block">{activePointData ? activePointData.title : activeRouteName}</span>
            {currentStopNumber && renderNavIcon('next')}
        </div>
        <div onClick={handleOpenStops} ref={buttonRef} className={'stops-modal-button'}>
            {openStops ? <div className="d-flex align-items-center gap-3"><img src={arrowLeft} alt=""/> <span>Explore the map</span>
            </div> : <div className="d-flex align-items-center gap-3"><span>Explore the stops</span> <img
                    src={arrowDark} alt=""/></div>}
        </div>
        <div className="position-absolute bottom-0 w-100" style={{top: mapTop}}>
            <div
                    ref={mapContainer}
                    className="rounded-lg position-absolute bottom-0"
                    style={{width: '100%', height: '100%'}}
            />
            <div style={{display: hideTopWidget && 'none'}}>
                <div id="menu">
                    <a href={'/locations/5'}><img style={{maxWidth: 45}} src={homeIcon} alt="homeIcon"/></a>
                    <a onClick={(e) => {
                        e.preventDefault();
                        setInfoModal(true);
                    }}><img style={{maxWidth: 45}} src={infoIcon} alt="infoIcon"/></a>

                </div>
                <div id="zoom-controls">
                    <span id="zoom-in"><img style={{maxWidth: 45}} className="d-block" src={zoomIn}
                                            alt="zoom-in"/></span>
                    <span id="zoom-out"><img style={{maxWidth: 45}} className="d-block" src={zoomOut}
                                             alt="zoom-out"/></span>
                </div>
            </div>
            <BottomWidget activeRouteName={activeRouteName} currentStopNumber={currentStopNumber} mapTop={mapTop}
                          activePointData={activePointData} widgetRef={widgetRef}
                          audioPlayStatus={audioPlayStatus}
                          setAudioPlayStatus={setAudioPlayStatus}/>
            <Stops setOpenStops={setOpenStops} openStops={openStops} mapData={mapData} mapTop={mapTop}
                   map={mapObject}/>
            <InfoModal infoModal={infoModal} setInfoModal={setInfoModal}/>
            <FinalScreen setFinalModal={setFinalModal} finalModal={finalModal} startTourAgain={startTourAgain}/>

        </div>


    </>);
};


export default Mapbox;