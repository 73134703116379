import React, {useState, useEffect, useContext} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {Settings} from '../store';
import Loader from './Loader';
import Disclaimer from './Disclaimer';

const Locations = () => {

    const dispatch = useContext(Settings.Dispatch);
    const settingsState = useContext(Settings.State);
    const {locations} = settingsState;
    const [locationLoader, setLocationLoader] = useState(false);
    useEffect(() => {
        if (!locations.length) {
            axios.get(process.env.REACT_APP_LOCATIONS).then(response => {
                let ajaxData = response.data.data;
                dispatch({type: 'setLocations', payload: ajaxData});
                setLocationLoader(true);
            }).catch(error => console.error('Error:', error));
        }
    }, []);

    return (

            <div>

                {!locationLoader && <Loader/>}
                <h1>All Locations</h1>
                <ul>
                    {locations && locations.map(location => (
                            <li key={location.id}>
                                {/* Link to the single location page using the location ID */}
                                <Link to={`/locations/${location.id}`}>{location.name}</Link>
                            </li>
                    ))}
                </ul>
            </div>
    );
};

export default Locations;