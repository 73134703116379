import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import axios from 'axios';
import Disclaimer from './Disclaimer';
import RouteCard from './RouteCard';


const CityRoutes = () => {
    // Access the locationId parameter from the URL
    const {locationId} = useParams();

    const [location, setLocation] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;

        const fetchLocation = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_LOCATIONS + '/' + locationId);
                if (isMounted) {
                    setLocation(response.data.data);
                    console.log(response.data.data);
                }
            } catch (err) {
                if (isMounted) {
                    setError(err.message || 'Something went wrong');
                }
                console.error('Error:', err);
            }
        };

        fetchLocation();

        return () => {
            isMounted = false;  // Cleanup: prevent setting state if component is unmounted
        };
    }, []);

    // If the location doesn't exist, return a not found message
    if (!location) {
        return <h1>Location Not Found</h1>;
    }

    return (<>
                <Disclaimer/>
                <div id="routes-list">
                    {location && location.map(location => (
                            <RouteCard status={location.status} key={location.id} text={location.text} stops={location.stops}
                                       title={location.title} image={location.image} distance={location.distance}
                                       url={`/city-route/${location.id}`}/>

                    ))}
                </div>

            </>
    );
};

export default CityRoutes;