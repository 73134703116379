import React, {useEffect, useRef, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Lena_Chris from '../images/Lena_Chris_v1.png';
import arrowLeft from '../images/arrowLeftWhite.svg';

const InfoModal = (props) => {

    const {setInfoModal, infoModal} = props;

    const hideInfoModal = () => {
        setInfoModal(false);
    };

    return (<>
                <Modal size="sm"
                       fullscreen={true}
                       id={'info-modal'}
                       scrollable={false}
                       dialogClassName={'d-flex align-items-center m-0'}
                       aria-labelledby="contained-modal-title-vcenter"
                       centered
                       backdrop={true}
                       className={'p-3 m-0 info-modal'}
                       show={infoModal} onHide={hideInfoModal}
                >
                    <Modal.Body className={'p-0'}>
                        <img className="w-100" src={Lena_Chris}
                                                         alt="Lena_Chris"/>
                        <div className="p-4" style={{background:'#F4F286'}}>
                            <h2>A little about us</h2>
                            <p>Dr Lena Mattheis and Prof Chris Mounsey are the team behind ListenQueer. We are both
                                researchers specialising in queer and trans literature and history and love sharing that
                                research with others.
                                <br/><br/>
                                Lena (they/she) hosts the Queer Lit podcast and has a soft spot for queer Christmas
                                novels.
                                <br/><br/>
                                Chris (he/him) works in disability studies and wears the best dungarees in town. </p>
                            <a className="button d-inline-flex align-items-center justify-content-center gap-3"
                               onClick={(e) => {
                                   e.preventDefault();
                                   setInfoModal(false);
                               }}><img src={arrowLeft} alt="arrowLeft"/><span>Back to the map</span></a>
                        </div>

                    </Modal.Body>
                </Modal>
            </>
    )
            ;
};

export default InfoModal;
