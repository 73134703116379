import React, {useState, useRef, useEffect, useContext} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import arrowDark from '../../images/arrow-dark.svg';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, EffectFade, Zoom} from 'swiper';
import leftArrow from '../../images/leftSliderArrow.svg';
import rightArrow from '../../images/rightSliderArrow.svg';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/zoom';
import {Settings} from '../../store';
import MediaComponent from '../MediaComponent';

const Gallery = (props) => {

    const {mapTop} = props;
    const [swiper, setSwiper] = useState();
    const [activeCaption, setActiveCaption] = useState('');
    const [expanded, setExpanded] = useState(false);
    const settingsState = useContext(Settings.State);
    const {activePointData, galleryModal} = settingsState;
    const dispatch = useContext(Settings.Dispatch);
    const [gallery, setGallery] = useState(activePointData.gallery);

    const hideGalleryModal = () => {
        dispatch({
            type: 'setStates',
            payload: {
                galleryModal: false,
                hideTopWidget: false,
            },
        });
    };
    const showGalleryModal = () => {
        dispatch({
            type: 'setStates',
            payload: {
                galleryModal: true,
                hideTopWidget: true,
                transcriptModal: false,
            },
        });
    };

    useEffect(() => {
        if (swiper && swiper.params) {
            // Set initial caption for the first slide
            if (gallery && gallery.length > 0) {
                setActiveCaption(gallery[0].caption);  // Set first caption initially
            }
        }
    }, [swiper, gallery]);

    useEffect(() => {
        setGallery(activePointData.gallery);
    }, [activePointData, galleryModal]);

    const handleSlideChange = (swiper) => {
        const activeSlide = swiper.activeIndex;
        if (gallery[activeSlide].caption !== undefined && gallery[activeSlide].caption !== '') {
            setActiveCaption(gallery[activeSlide].caption);
        } else {
            setActiveCaption('');
        }
    };

    return (<>
        {!galleryModal &&
                <Button className="gallery-button" onClick={showGalleryModal}><span>Gallery</span><img src={arrowDark}
                                                                                                       alt=""/></Button>}
        <Modal size="xl"
               style={{top: mapTop}}
               fullscreen={false}
               scrollable={false}
               id={'gallery-modal'}
               dialogClassName={expanded ? 'd-flex m-0  align-items-end expanded' : 'd-flex m-0  align-items-end'}
               aria-labelledby="contained-modal-title-vcenter"
               className={'widget-modal'}
               backdrop={false}
               show={galleryModal} onHide={hideGalleryModal}

        > <Modal.Header className="p-3" closeButton>
            <span style={{display: expanded ? 'inline-block' : 'none'}} onClick={() => {
                setExpanded(!expanded);
            }}><svg xmlns="http://www.w3.org/2000/svg" width="23.414" height="24.813" viewBox="0 0 23.414 24.813">
  <g id="Group_141" data-name="Group 141" transform="translate(-28.551 -118.375)">
    <path id="Path_116" data-name="Path 116" d="M11.714,0V2.271h5.507L9.691,10.211l1.515,1.6,7.539-7.938V9.682H20.9V0Z"
          transform="translate(18.859 131.374)" fill="#001233"/>
    <path id="Path_117" data-name="Path 117" d="M11.714,0V2.271h5.507L9.691,10.211l1.515,1.6,7.539-7.938V9.682H20.9V0Z"
          transform="translate(61.656 130.188) rotate(180)" fill="#001233"/>
  </g>
</svg>
</span>
            <span onClick={() => {
                setExpanded(!expanded);
            }} style={{display: expanded ? 'none' : 'inline-block'}}><svg xmlns="http://www.w3.org/2000/svg"
                                                                          width="20.899" height="22.027"
                                                                          viewBox="0 0 20.899 22.027">
  <path id="Path_111" data-name="Path 111"
        d="M11.714,0V2.271h5.507L9.691,10.211,2.155,18.148v-5.8H0v9.681H9.186V19.754H3.675l7.531-7.94,7.539-7.938V9.682H20.9V0Z"
        transform="translate(0 0)" fill="#001233"/>
</svg>
</span>
            {expanded && <span className="d-block ms-4"><small>Zoom into the image</small></span>}
        </Modal.Header>
            <Modal.Body className="text-center d-flex flex-column">
                <Swiper
                        initialSlide={0}
                        modules={[Navigation, Zoom]}
                        // effect="fade"
                        // fadeEffect={{crossFade: true}}
                        zoom={true}
                        slidesPerView={1}
                        navigation={{nextEl: '.arrow-next', prevEl: '.arrow-prev'}}
                        onSwiper={(swiper) => {
                            setSwiper(swiper);    // Get reference to the swiper instance
                        }}
                        loop={false}
                        onSlideChange={handleSlideChange}
                >
                    {gallery && gallery.map((item) => <SwiperSlide data-item-slide-caption={item.caption}
                                                                   key={item.id}>
                        <div className="swiper-zoom-container">
                            <MediaComponent url={item.url}/>
                        </div>

                    </SwiperSlide>)}

                </Swiper>
                <div className="slider-navigation d-flex align-items-center justify-content-between gap-2 pt-3">
                    <span className="arrow-prev">
                        <img src={leftArrow} alt="leftArrow"/>
                    </span>
                    <div id="image-caption">{activeCaption}</div>
                    <span className="arrow-next">
                        <img src={rightArrow} alt="rightArrow"/>
                    </span>
                </div>
            </Modal.Body>
        </Modal>
    </>);
};
export default Gallery;