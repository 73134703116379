import React from 'react';
import logo from '../images/lq-logo.svg';
import LetsExplore from './LetsExplore';
import exploreImage from '../images/image1.jpg';
import shape from '../images/home-shape.svg';

const Home = () => {

    return <>
        <div className="h-100 text-center d-flex align-items-center justify-content-center flex-column overflow-hidden"
             style={{background: '#BC88FC', backgroundSize: '100%'}}>
            <div></div>
            <div className="d-inline-block px-3 pb-4">
                <img src={logo} alt="logo"/>
                <p className="fs-26 my-4" style={{lineHeight: '35px'}}>Accessible audio-based queer history</p>
                <LetsExplore exploreImage={exploreImage}/>
            </div>
            <div className="position-relative w-100">
            <img className="w-100 position-absolute start-0 top-0" src={shape} alt="shape"/>
            </div>
        </div>
    </>;
};


export default Home;