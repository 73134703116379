import React from 'react';
import Mapbox from './Mapbox';
import Gallery from './layout/Gallery';
import Stops from './Stops';

const CityRoute = () => {

    return <div>
        <Mapbox/>

    </div>;
};

export default CityRoute;