import React, { StrictMode } from 'react';

import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Store} from './store';
const root = ReactDOM.createRoot(document.getElementById('root'));




root.render(
  <Store>
    <App />
  </Store>
);
reportWebVitals();