import React, { useEffect, useState } from 'react';
import Home from './components/Home';
import './App.scss';
import {usePageTracking} from './services/usePageTracking';
import gsap from 'gsap';
import 'swiper/css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Locations from './components/Locations';
import CityRoutes from './components/CityRoutes';
import CityRoute from './components/CityRoute';
import LetsExplore from './components/LetsExplore';
import Disclaimer from './components/Disclaimer';
import Desktop from './components/Desktop';
import NotFoundPage from './components/NotFoundPage';


function App() {
    const [isMobile, setIsMobile] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (!isMobile && window.innerWidth >768) {
        return <Desktop/>;
    }
    // usePageTracking();
    // gsap.config({
    //     nullTargetWarn: false,
    // });
    return (
            <Router>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/explore/" element={<LetsExplore/>}/>
                        <Route path="/disclaimer/" element={<Disclaimer/>}/>
                        <Route path="/locations/" element={<Locations/>}/>
                        <Route path="/locations/:locationId" element={<CityRoutes/>}/>
                        <Route path="/city-route/:routeId" element={<CityRoute/>}/>
                        <Route path="*" element={<NotFoundPage />} />
                    </Routes>
            </Router>
    );
}

export default App;
