import React, {useContext} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import arrowDark from '../../images/arrow-dark.svg';
import {Settings} from '../../store';

const Transcript = (props) => {
    const dispatch = useContext(Settings.Dispatch);
    const settingsState = useContext(Settings.State);
    const {transcriptModal} = settingsState;
    const {transcript, mapTop} = props;

    const hideTranscriptModal = () => {
        dispatch({
            type: 'setStates',
            payload: {
                transcriptModal: false,
                hideTopWidget: false,
            },
        });
    };
    const showTranscriptModal = () => {
        dispatch({
            type: 'setStates',
            payload: {
                transcriptModal: true,
                hideTopWidget: true,
                galleryModal: false,
            },
        });
    };
    return (<>
        {!transcriptModal &&
                <Button className={'transcript-button'} onClick={showTranscriptModal}><span>Transcript</span><img
                        src={arrowDark}
                        alt="Transcript"/></Button>}
        <Modal size="xl"
               style={{top: mapTop}}
               fullscreen={false}
               scrollable={true}
               dialogClassName={'d-flex m-0  align-items-end'}
               aria-labelledby="contained-modal-title-vcenter"
               className={'widget-modal'}
               backdrop={false}
               id={'transcript-modal'}
               show={transcriptModal} onHide={hideTranscriptModal}
        >
            <Modal.Header className="p-3" closeButton>
            </Modal.Header>
            <Modal.Body className="pt-0 fs-17 fw-400">
                <div dangerouslySetInnerHTML={{__html: transcript}}></div>
            </Modal.Body>
        </Modal>
    </>);
};
export default Transcript;