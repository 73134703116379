import React from 'react';

function MediaComponent({ url }) {
    // Define video extensions
    const videoExtensions = ['mp4', 'webm', 'ogg'];

    // Helper function to check if URL is a video
    const isVideo = (url) => {
        const extension = url.split('.').pop().toLowerCase();
        return videoExtensions.includes(extension);
    };

    return (
            <>
                {isVideo(url) ? (
                        <video controls width="100%" height="auto">
                            <source src={url} type={`video/${url.split('.').pop()}`}/>
                            Your browser does not support the video tag.
                        </video>
                ) : (
                        <img className="img-fluid" src={url} key={url} alt="gallery"/>
    )
}
</>
)
    ;
}

export default MediaComponent;