export const mapFlyTo = (map, lng, lat, midPoint = false) => {

    map && map.flyTo({
        center: [lng, lat],
        speed: midPoint ? 0.6 : 0.8,
        zoom: midPoint ? 17 : 19,
    });
};

export const setActivePin = (map, activeMarkerId, id, midPoint = false) => {
    setTimeout(() => {


        activeMarkerId = id;
        const conditions = ['==', ['get', 'id'], activeMarkerId];

        map.setLayoutProperty('pointMarker', 'icon-image', [
            'case',
            conditions,
            'active-marker', // Active marker icon
            'normal-marker', // Default icon for other markers
        ]);

        map.setPaintProperty('pointMarker', 'text-color', [
            'case',
            conditions,
            '#fff', // Color for active marker text
            '#000', // Default color for other markers
        ]);

        map.setLayoutProperty('pointMarker', 'text-size', [
            'case',
            conditions,
            26, // Size for active marker
            26, // Default size (no change needed but kept for clarity)
        ]);

        map.setLayoutProperty('pointMarker', 'icon-offset', [
            'case',
            conditions,
            [0, -52], // Offset for active marker
            [0, -40], // Default offset for other markers
        ]);

        map.setLayoutProperty('pointMarker', 'text-offset', [
            'case',
            conditions,
            [0, -2.2], // Text offset for active marker
            [0, -1.8], // Default offset for other markers
        ]);

    }, 1);
};

export const detectStopNumber = (totalStops, title, lng, lat) => {
    return totalStops.find((item) => {
        return item.title === title && item.lng === lng && item.lat === lat;
    });
};

export const getStopData = (totalStops, title, lng, lat) => {
    return totalStops.find((item) => {
        return item.title === title && item.lng === lng && item.lat === lat;
    });
};


export const calculateMidpoint = (coord1, coord2) => {
    const midLat = (coord1[1] + coord2[1]) / 2;
    const midLon = (coord1[0] + coord2[0]) / 2;
    return [midLon, midLat];
};
